<template>
  <div class="cntSuccess">
    <div class="pagamento">
      <v-icon color="green" x-large>
        mdi-check-circle
      </v-icon>
      <p class="successPagamento">pagamento avvenuto con successo. <br>Il tuo numero ordine è {{ numeroOrdine }}</p>
      <div style="text-align: center">Per informazioni sullo stato dell'ordine contattare il numero: {{ getPhone() }} o mandare una mail all'indirizzo {{ getEmail() }}</div>
      <v-btn v-if="isPhone" dark color="black" style="margin-top: 30px" :to="'/'">Torna alla home</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import catalogoApi from "../api/catalogoApi";
import ordiniApi from '../api/ordiniApi';

export default {
  props: ["isPhone"],

  data() {
    return {
      numeroOrdine: ""
    }
  },

  async mounted() {
    this.numeroOrdine = localStorage.getItem("numeroOrdine");
    await ordiniApi.ordinePagato(this.numeroOrdine);
    localStorage.removeItem("numeroOrdine");
    this.carrello.forEach(async element => {
      this.deleteItemInCarrello(element);
      await catalogoApi.itemInvisible(element);
    });
  },

  computed: {
    ...mapState({
      contatti: state => state.contatti
    }),

    ...mapGetters({
      carrello: "getCarrello"
    })
  },

  methods: {
    getContatto(type) {
      var contatto = this.contatti.find(item => item.type == type);
      return contatto.info;
    },

    getPhone() {
      return this.getContatto("phone");
    },

    getEmail() {
      return this.getContatto("mail");
    },

    ...mapMutations(["deleteItemInCarrello"])
  }
};
</script>

<style lang="scss">
.cntSuccess {
  margin: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pagamento {
    background-color: white;
    border-radius: 12px;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    width: 50%;
    .v-icon {
      font-size: 80px !important;
    }
    .successPagamento {
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.phone {
  .cntSuccess {
    .pagamento {
      width: 90vw;
    }
  }
}
</style>
